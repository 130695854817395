.trabajar{
    height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px 80px;
    justify-content: space-between;
}

.trabajar-info{
    display: flex;
    flex-direction: row;
    font-family: "Fira Sans", sans-serif;
}

.trabajar-izq, .trabajar-der{
    flex:1;
}

.trabajar-der {
    position: relative;
}

.trabajar-der img{
    position: absolute;
    width: 600px;
    left:60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trabajar-izq h2{
    font-size:60px;
    color:var(--colorNaranja);
    margin-top:60px;
    text-align: left;
}

.trabajar-izq p{
    text-align: justify;
    font-size: 1rem;
    width: 500px;
    margin-top: 30px;
}

.trabajar-logos{
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    flex-direction: row;
    height: 60px;

}

.trabajar-logos img{
   
    object-fit: contain;
    
    
}

@media screen and (min-width:340px) and (max-width: 800px){

    .trabajar{
        height: fit-content;
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 20px 40px;
        justify-content: space-between;
        margin-bottom: -1px;
    }

    .trabajar-logos{
        display: flex;
        gap: 10px;
        justify-content: space-evenly;
        flex-direction: column;
        height: fit-content;
        align-items: center;
        margin-top:50px
    
    }

    .trabajar-logos img{
   
        width: 50%;
        
        
    }

    .trabajar-info{
        display: flex;
        flex-direction: column;
        font-family: "Fira Sans", sans-serif;
    }

   
    

    .trabajar-izq h2{
        font-size:60px;
        color:var(--colorNaranja);
        margin-top:60px;
        text-align: center;
    }

    .trabajar-izq p{
        text-align: justify;
        font-size: 1.2rem;
        width: fit-content;
        margin-top: 30px;
    }

    .trabajar-der {
        display: none;
    }

    .trabajar-der img{
        position: absolute;
        width: 50%;
        left:60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    

}