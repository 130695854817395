.contacto{
    display: flex;
    padding: 30px 80px;
}

.contacto :nth-child(1){
    flex: 1;
}

.contacto :nth-child(1) img{
    width: 400px;
}
.contacto div:nth-child(2)
{
    display: flex;
    flex:1.5;
    color:white;
    justify-content: center;
    align-items: center;
    width: 500px;
}

form {
    
    width: 100%;
    font-family: "Fira Sans", sans-serif;
    
    
}

form >div{
    display: flex;
    flex-direction: column;
    text-align: left;
}

form div h2{
    font-size:40px;
    text-align: center;
    color:var(--colorNaranja);
}

input{
    padding: 10px 17px;
    border-radius: 20px;
    outline: none;
    border:none;
    margin-top:10px;
    margin-bottom: 10px;
}

textarea{
   outline: none;
   border-radius: 20px;
   margin-top: 10px;
   padding: 10px 17px;

}

button{
    margin-top: 30px;
    padding: 10px;
    border-radius: 20px;
    width: 150px;
    background-color: var(--colorNaranja);
    color:white;
    font-size:16px;
    transition: all ease .2s;
    cursor: pointer;
    font-weight: 700;
    outline: none;
    border:none;
}

button:hover{
    background-color: white;
    color:var(--colorNaranja)
}

.mensaje-enviado{
    font-family: "Fira Sans", sans-serif;
    color:white;
    font-size: 16px;
    background-color: var(--colorNaranja);
    border-radius: 25px;
    width: fit-content !important;
    margin-top: 20px;
    padding: 10px;

}

@media screen and (min-width:340px) and (max-width: 800px){

    .contacto :nth-child(1) img{
        display: none;
    }


    .contacto{
        display: flex;
        padding: 30px 50px;
        
    }


    .contacto div:nth-child(2)
{
    display: flex;
    flex:1.5;
    color:white;
    justify-content: center;
    align-items: center;
    width: 75vw;
}


button{
    margin-top: 30px;
    padding: 15px 10px;
    border-radius: 20px;
    width: 100%;
    background-color: var(--colorNaranja);
    color:white;
    font-size:16px;
    transition: all ease .2s;
    cursor: pointer;
    font-weight: 700;
    outline: none;
    border:none;
    font-size: 18px;
}

}