*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  background-color: #09203f;
}

:root{
  --azul:#09203f;
  --colorTexto:#1c3d96;
  --colorNaranja:#f18c1a;
  --font-p :1.2rem;
}