.proyectos{
    background-color: white;
    padding: 40px 80px;
    
}
.proyecto{
    display: flex;
    background-color: var(--colorNaranja);
    border-radius: 20px;
    overflow: hidden;
    height: fit-content;
    margin-bottom: 20px;
    
}
.proyectos div h2{

    font-family: "Fira Sans", sans-serif;
    
    font-size: 60px;
    color:var(--colorNaranja);
    padding-top: -20px;
    margin-bottom: 20px;
    

}

.proyecto-detalle{
    flex:1;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 30px;
    font-family: "Fira Sans", sans-serif;
}

.proyecto-detalle div h2{
    color:white;
    margin-bottom: 20px;
    font-size: 30px;
}

.titulo-descripcion{
    font-size: 22px !important;
    margin-bottom: 20px !important;
}


.proyecto-detalle div h3{
    font-size: 16px;
}

.proyecto-detalle div h3:nth-child(3){
    margin-bottom: 10px;
}

.proyecto-detalle div p{
    text-align: justify;
    font-size:14px;
}

.proyecto-imagen{
    flex:1.5;
   
    
}

.proyecto-imagen img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
    
}

.tecnologias{
    display: flex;
    gap:10px;
    justify-content: end;
    margin-top: 10px;
    margin-bottom: 10px;
    
    
}

.tec1{
    width:60px;
    height: 60px;
    background-color: white;
    border-radius: 20px;
    overflow: hidden;
}
.tec1 img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (min-width:340px) and (max-width: 800px){

    .proyectos{
        background-color: white;
        padding: 40px 20px;
        
    }


    .proyectos div h2{

    font-family: "Fira Sans", sans-serif;
    
    font-size: 50px;
    color:var(--colorNaranja);
    padding-top: -20px;
    margin-bottom: 20px;
}


.proyecto{
    display: flex;
    background-color: var(--colorNaranja);
    border-radius: 20px;
    overflow: hidden;
    height: fit-content;
    margin-bottom: 20px;
    width: fit-content;
    
}

.proyecto-detalle{
    flex:1;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 30px;
    font-family: "Fira Sans", sans-serif;
}

.proyecto-detalle div h2{
    color:white;
    margin-bottom: 20px;
    font-size: 30px;
}

.proyecto-imagen{
    display: none;
   
    
}

.proyecto-detalle div p{
    text-align: justify;
    text-wrap: wrap;
    font-size:var(--font-p);
}


}