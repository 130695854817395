.navbar{
    
    padding: 20px 60px;
    
   
}

header{
    display: flex;
    
    
}

.btnMenuHam{
    display: none;
}

.menuBurger{
    position:fixed;
    background-color: white;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    font-family: "Fira Sans", sans-serif;
    line-height: 80px;
    /* 
    
    
    
     */
    
    
}


.menuBurger li{
    font-size: 35px;
    cursor: pointer;
    
    

}


header div:nth-child(1)
{
    flex:1;
    text-align: left;
    display: flex;
    align-items: center;
    

}

header div:nth-child(1) img{
    width: 200px;
    display: flex;
    justify-content: flex-start;
    margin-left: -50px;
}


header div:nth-child(2){
    display: flex;
    align-items: center;
    position: relative;
}

nav ul {
    display: flex;
    gap:20px;
    list-style: none;
    color:white;
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
 
}

nav ul li{
    transition: all .2s ease-in;
}

nav ul li:hover{
    color:white;
    background-color: var(--colorNaranja);
    padding: 10px;
    border-radius: 10px;
}



@media screen and (min-width:340px) and (max-width: 800px){

    .navbar{
    
        display: flex;
        padding-top: 10px ;
        
        margin-bottom: -80px;
        align-items: center;
        justify-content: center;
        
       
    }

    .btnMenuHam{
        display:block;
        width: fit-content;
        height:fit-content;
        transform: scale(2.8);
        cursor: pointer;
        color:white;
        margin-left: 30px;
    }
    

 header{
    display: flex;
    align-items: center;
    justify-content: center;
 }
    


    nav{
        display: none;
    }

}


