footer{
    display: flex;
    padding: 20px 80px;
    justify-content: space-between;
    height: 250px;
    
}

footer > div {
    flex:1;
}
footer :nth-child(1){
    text-align: left;
}

.footer-izq img{
  width: 200px;
}


.footer-der{
    display: flex;
    justify-content: right;
    text-align: right;
    font-family: "Fira Sans", sans-serif;
    color:white;
   
    font-size: 1rem;
}


.footer-der > div > p > a{
    text-decoration: none;
    color:white;
}

@media screen and (min-width:340px) and (max-width: 800px) {

    footer{
        display: flex;
        padding: 20px 60px;
        justify-content: space-between;
        height: 250px;
        flex-direction: column;
        align-items: center;
        
    }
    

}