
.slider{
    height: 100vh;
    
    display: flex;
    padding: 20px 80px;
    flex-direction: column;
    background-image: url("../../image/bg.jpg");
    background-size: cover;
}

.slider > :nth-child(1){
    margin-top: -30px;
    margin-bottom: 100px;
}

.slider-wrapper{
    display: flex;

}
.slider-izq{
    display: flex;
    flex-direction: column;
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    justify-content: center;
    flex:1;
}

.slider-izq h1{
    color:white;
    font-size: 65px;
    text-align: left;
    margin-bottom: 30px;
}

.slider-izq p{
    color:white;
    text-align: left;
    text-align: justify;
    margin-bottom: 2rem;
    width: 450px;
    line-height: 25px;
}

.slider-izq button{
    border-radius: 10px;
    border:none;
    padding: 10px 20px;
    background-color: var(--colorNaranja);
    color:white;
    font-family: "Fira Sans", sans-serif;
    font-size: 1.2rem;
    width: fit-content;
    cursor: pointer;
}

.slider-der{
    display:flex;
    flex-direction: column;
    flex:1;
    position: relative;
    
}

.slider-der > :nth-child(1){
    z-index: 10;
    width: 350px;
    position: absolute;
    top:-10px;
    left: 200px;
}

.slider-der > :nth-child(2){
    z-index: 10;
    width: 160px;
    position: absolute;
    top:250px;
    left:400px;
}

.slider-der > :nth-child(3){
    z-index: 10;
    width: 120px;
    position: absolute;
    top:50px;
}

.slider-der img{
    width: 620px;
    position: absolute;
    left: 50px;
    top:-50px;
}


@media screen and (min-width:340px) and (max-width: 800px){

    .slider{
        height: 100vh;
        
        display: flex;
        padding: 20px 40px;

    }
    
    .slider-izq{
        display: flex;
        flex-direction: column;
        font-family: "Fira Sans", sans-serif;
        font-weight: 400;
        justify-content: center;
        flex:1;
        flex-wrap: wrap;
        align-items: center;

    }

    .slider-izq h1{
        color:white;
        font-size: 40px;
        text-align: left;
        margin-bottom: 30px;
        text-wrap:wrap;
        ;
    }

    .slider-izq p{
        color:white;
        text-align: left;
        text-align: justify;
        margin-bottom: 2rem;
        width:100%;
        line-height: 25px;
        font-size: var(--font-p);
    }

    .slider-izq button{
        border-radius: 10px;
        border:none;
        padding: 10px 20px;
        background-color: var(--colorNaranja);
        color:white;
        font-family: "Fira Sans", sans-serif;
        font-size: 1.2rem;
        width: 100%;
        cursor: pointer;
       
    }

    .slider-der{
       display: none;
        
    }

}