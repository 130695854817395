
.somos{
    height: 300px;
    background-color:var(--colorNaranja);
    padding: 0px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.somos :nth-child(1) h2{
    font-family: "Fira Sans", sans-serif;
    font-weight: 800;
    font-size: 60px;
    color:white;
    padding-top: 20px;
}

.somos :nth-child(2){

    
    background-color: white;
    padding: 20px 20px;
    border-radius: 20px;
    width: 95%;
    margin-top:30px;
    font-family: "Fira Sans", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color:var(--colorTexto);
    line-height: 30px;

}

@media screen and (min-width:340px) and (max-width: 800px){

    .somos{
        height: 100vh;
        background-color:var(--colorNaranja);
        padding: 0px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: -1px;
    }

    .somos :nth-child(2){

    
        background-color: white;
        padding: 20px 20px;
        border-radius: 20px;
        width: 95%;
        margin-top:30px;
        font-family: "Fira Sans", sans-serif;
        font-weight: 500;
        font-size: var(--font-p);
        color:var(--colorTexto);
        line-height: 30px;
    
    }

}